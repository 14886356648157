<template>
  <!-- 租车城市管理 -->
  <div class="CarRentalCityManage" @click="hide">
    <div class="header">
      <p>租车城市管理</p>
      <el-button
        v-if="listFind('新增城市')"
        type="primary"
        size="small"
        @click="newCityFun"
      >
        <i
          class="iconfont its_tianjia"
          style="position: relative; top: 1px"
        ></i>
        新增城市
      </el-button>
    </div>
    <Table
      :col-class-type="true"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('编辑')"
            type="text"
            size="small"
            sort="primary"
            @click="editFun(scope.scopeRow)"
            >编辑</el-button
          >
          <el-button
            v-if="listFind('删除')"
            type="text"
            size="small"
            sort="danger"
            @click="deleteFun(scope.scopeRow)"
            >删除</el-button
          >
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      width="600"
      :header-text="headerText"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <!-- 新增城市 -->
      <div>
        <el-form
          ref="Form"
          :model="cityForm"
          :rules="cityRule"
          label-width="140px"
          label-position="right"
          class="demo-form dialog-form"
        >
          <el-form-item label="城市名称：" prop="cityName">
            <el-input
              v-model="cityForm.cityName"
              placeholder="请输入城市名称"
              onkeyup="this.value=this.value.replace(/\s+/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="区县：">
            <div
              v-for="(item, index) in countyArray"
              :key="index"
              class="county"
            >
              <div
                v-show="!item.inputShow"
                class="checked"
                @mouseleave="mouseleave(item)"
                @click="checkedItemFun(item)"
              >
                <!-- 多选框图标 -->
                <i
                  :class="
                    item.checked ? 'its_a-xuanzhongbeifen2' : 'its_weixuanzhong'
                  "
                  class="iconfont"
                ></i>
                <!-- 文字 -->
                <span>{{ item.value }}</span>
                <!-- 三个点 -->
                <div
                  class="iconfont its_icon_more02 displayShow"
                  @click.stop="
                    () => {
                      item.menuShow = true;
                    }
                  "
                >
                  <div v-show="item.menuShow" class="menu">
                    <div class="li" @click.stop="updateCounty(item)">编辑</div>
                    <div class="li" @click.stop="deleteCounty(item, index)">
                      删除
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="item.inputShow" class="inputBlur">
                <el-input
                  v-model.trim="item.value"
                  onkeyup="this.value=this.value.replace(/\s+/g,'')"
                ></el-input>
                <i
                  class="iconfont its_queren green"
                  @click="Countydetermine(item)"
                ></i>
                <i
                  class="iconfont its_quxiao red"
                  @click="CountycloseFun(item)"
                ></i>
              </div>
            </div>
            <div class="addCounty" @click="addCountyFun">
              <i class="iconfont its_tianjia"></i>
              <span>添加区县</span>
            </div>
          </el-form-item>
          <el-form-item label="启用：">
            <el-switch
              v-model="cityForm.status"
              active-color="#13ce66"
              inactive-color="#E3E3E3"
              @click.stop.native
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import {
  cityInsertAPI,
  cityRenderAPI,
  rentalCityUpdateAPI,
  rentalCityDeleteAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      tableForm: {
        pageSize: 10,
        currentPage: 1,
      },
      countyArray: [],
      cityForm: {
        cityName: "",
        status: 1,
      },
      cityRule: {
        cityName: [
          { required: true, message: "请填写城市名称", trigger: "blur" },
        ],
      },
      show: false,
      headerText: "",
      tableData: [],
      titleName: [
        {
          title: "城市名称",
          props: "cityName",
          width: 130,
        },
        {
          title: "可用取车区县",
          props: "districts",
          SpecialJudgment: (res) => {
            let label = "";
            res.map((item) => {
              if (item.status === 0) {
                label += item.districtName + "　";
              }
            });
            return label.substr(0, label.length - 1);
          },
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    cityName: params.cityName,
                    status: params.status === 0 ? 1 : 0,
                    id: params.id,
                    districts: params.districts,
                  };
                  this.updateData(obj);
                },
              },
            });
          },
        },
      ],
      CountyTemporary: "",
      total: 0,
      cityId: null,
    };
  },
  mounted() {
    this.renderData();
  },
  methods: {
    mouseleave(item) {
      item.menuShow = false;
    },
    hide() {
      this.countyArray.map((item) => {
        item.menuShow = false;
      });
    },
    // 点击表格编辑按钮
    editFun(scope) {
      console.log(scope);
      this.cityId = scope.id;
      this.headerText = "编辑";
      this.show = true;
      this.cityForm.cityName = scope.cityName;
      this.cityForm.status = scope.status === 0 ? true : false;
      scope.districts.map((item) => {
        this.countyArray.push({
          id: item.id,
          value: item.districtName,
          checked: item.status === 0 ? true : false,
          status: "existence",
          inputShow: false,
          menuShow: false,
        });
      });
    },
    // 点击表格删除按钮
    deleteFun(scope) {
      this.$confirm("确认删除吗？", "提示")
        .then(() => {
          rentalCityDeleteAPI({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 表格数据更新接口方法
    updateData(obj) {
      rentalCityUpdateAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
          this.closePopupFun();
          this.$message.success("修改成功");
        }
      });
    },
    // 表格数据接口渲染
    renderData() {
      cityRenderAPI(this.tableForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 新增城市接口方法
    cityInsertFun(districts) {
      cityInsertAPI({
        cityName: this.cityForm.cityName,
        status: this.cityForm.status ? 0 : 1,
        districts,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("新增成功");
          this.closePopupFun();
          this.renderData();
        }
      });
    },
    // 点击删除区县
    deleteCounty(item, index) {
      item.menuShow = false;
      console.log(item);
      this.$confirm("确认删除吗？", "提示")
        .then(() => {
          this.countyArray.splice(index, 1);
        })
        .catch(() => {});
    },
    // 点击新增区县
    addCountyFun() {
      let onOff = true;
      for (let i = 0; i < this.countyArray.length; i++) {
        if (this.countyArray[i].inputShow == true) {
          onOff = false;
          break;
        }
      }
      if (onOff) {
        this.countyArray.push({
          value: "",
          inputShow: true,
          menuShow: false,
          checked: false,
          status: "add",
        });
      }
    },
    // 点击勾选当前区县
    checkedItemFun(item) {
      item.checked = !item.checked;
    },
    // 点击区县菜单的编辑
    updateCounty(item) {
      item.inputShow = true;
      this.CountyTemporary = item.value;
    },
    // 点击绿色钩
    Countydetermine(item) {
      if (item.value == "") {
        this.$message.error("请输入区县名称！");
      } else {
        item.inputShow = false;
        if (item.status == "add") {
          item.status = "existence";
        }
      }
    },
    // 点击红色叉
    CountycloseFun(item) {
      console.log(item);
      // 点击的时候如果是新增的，就移除掉，否则就什么都不做
      if (item.status == "add") {
        this.countyArray.pop();
      } else {
        item.value = this.CountyTemporary;
        item.inputShow = false;
      }
    },
    // 点击新增城市
    newCityFun() {
      this.show = true;
      this.headerText = "新增";
    },
    // 弹窗确定按钮回调
    confirmFun() {
      let arraysDistricts = [];
      this.countyArray.map((item) => {
        arraysDistricts.push({
          status: item.checked ? 0 : 1,
          districtName: item.value,
          id: item.id ? item.id : null,
        });
      });
      console.log(arraysDistricts);
      if (this.headerText == "新增") {
        this.$refs.Form.validate((valid) => {
          if (valid) {
            this.cityInsertFun(arraysDistricts);
          }
        });
      } else if (this.headerText == "编辑") {
        this.updateData({
          id: this.cityId,
          cityName: this.cityForm.cityName,
          status: this.cityForm.status == false ? 1 : 0,
          districts: arraysDistricts,
        });
      }
    },
    // 取消按钮回调
    closePopupFun() {
      this.show = false;
      this.countyArray = [];
      this.cityForm = {
        cityName: "",
        status: 1,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.its_a-xuanzhongbeifen2 {
  color: #336fee;
}
.displayShow {
  display: inline-block;
  visibility: hidden;
}
.its_icon_more02 {
  color: #336fee;
  margin-left: 10px;
  position: relative;
  .menu {
    width: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px #b4b2b2;
    border-radius: 4px;
    position: absolute;
    top: 22px;
    left: 10px;
    .li {
      text-align: center;
      color: #333333;
      font-size: 14px;
      width: 100%;
    }
    .li:hover {
      background: #336fee;
      color: #ffffff;
      border-radius: 4px;
      display: inline-block;
    }
  }
}
.checked {
  cursor: pointer;
  height: 40px;
  i {
    font-size: 14px;
    margin-right: 6px;
  }
  span {
    display: inline-block;
    font-size: 14px;
  }
  &:hover {
    .displayShow {
      visibility: inherit;
    }
  }
}
.green {
  color: #4fb638;
}
.red {
  color: #f74242;
}
.inputBlur {
  display: flex;
  .el-input {
    width: 200px;
  }
  i {
    cursor: pointer;
    margin-left: 12px;
    font-size: 18px;
  }
}
.addCounty {
  cursor: pointer;
  display: inline-block;
  i {
    font-size: 14px;
    color: #336fee;
    margin-right: 6px;
  }
  span {
    font-size: 14px;
    color: #336fee;
  }
}

.CarRentalCityManage {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  .header {
    height: 40px;
    margin-bottom: 18px;
    p {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
    }
    .el-button {
      float: right;
      margin-top: 2px;
    }
  }
}
</style>
